import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'

import SEO from '../../components/seo'
import BannerSection from '../../components/BannerSection'
import Section from '../../components/Common/Section'
import Container from '../../components/Common/Container'
import Typography from '../../components/Common/Typography'
import { Button } from '../../components/Common/Button'
import ContactForm from '../../components/contact-form'

import { useTranslations } from '../../hooks/use-translations'
import { GlobalContext } from '../../context/GlobalContext'
import { AppRegionEnum } from '../../types/app-region.enum'

import ProfessionalismIcon from '../../svg/professionalism-icon'
import ResponsibilityIcon from '../../svg/responsibility-icon'
import InnovativenessIcon from '../../svg/innovativeness.icon'
import ClientCentricityIcon from '../../svg/client-centricity-icon'

import * as s from './about.module.scss'

interface IAuthorCard {
  image: IGatsbyImageData
  name: string
  position: string
  quote: string
  region: AppRegionEnum
}

const AuthorCard = ({ image, name, position, quote, region }: IAuthorCard) => (
  <div
    className={s.card}
    style={{ maxWidth: region === AppRegionEnum.Ru ? '260px' : '360px' }}
  >
    <div className="w-100 flex justify-center">
      <GatsbyImage image={image} title={name} alt={name} />
    </div>
    <Typography variant="body1" size={24} className={s.name}>
      {name}
    </Typography>
    <Typography variant="body1" color="blue">
      {position}
    </Typography>
    <Typography variant="body1" size={18} className={s.quote}>
      {quote}
    </Typography>
  </div>
)

const AboutPage: React.FC = () => {
  const images = useStaticQuery(imagesQuery)
  const [modal, setModal] = React.useState(false)
  const { t } = useTranslations()
  const { region } = React.useContext(GlobalContext)

  return (
    <>
      <SEO
        title={t('seo_title_about')}
        description={t('seo_description_about')}
      />
      <BannerSection
        imageDesktop={images.banner.childImageSharp.gatsbyImageData}
        imageMobile={images.bannerMobile.childImageSharp.gatsbyImageData}
        title={t('About Signal')}
      />
      <Section>
        <Container>
          <Typography variant="h2" size={40} mb={32}>
            {t('about_title1')}
          </Typography>
          <Typography variant="body1" size={18}>
            {t('about_lead1.1')}
          </Typography>
          {region === AppRegionEnum.Ru && (
            <Typography variant="body1" size={18} mt={16}>
              {t('about_lead1.2')}
            </Typography>
          )}
          <div className={s.imageGrid}>
            {images.imagesCompany.edges.map((item: any, index: any) => {
              return (
                <div key={index} className={s.imageCell}>
                  <GatsbyImage
                    image={item.node.childImageSharp.gatsbyImageData}
                    alt=""
                  />
                </div>
              )
            })}
          </div>
        </Container>
      </Section>
      <Section className={s.section}>
        <Container>
          <Typography variant="h2" size={40} mb={32}>
            {t('about_title2')}
          </Typography>
          <Typography variant="body1" size={18} mb={32}>
            {t('about_lead2.1')}
          </Typography>
          <div className={s.cards}>
            <AuthorCard
              image={images.Roman_Sigitov.childImageSharp.gatsbyImageData}
              name={t('Roman_Sigitov')}
              position={t('CEO')}
              quote={t('About_Roman_Sigitov')}
              region={region as AppRegionEnum}
            />
            <AuthorCard
              image={images.Alex_Popov.childImageSharp.gatsbyImageData}
              name={t('Alex_Popov')}
              position={t('CTO')}
              quote={t('About_Alex_Popov')}
              region={region as AppRegionEnum}
            />
            {region === AppRegionEnum.Us && (
              <AuthorCard
                image={images.Nikita_Pospelov.childImageSharp.gatsbyImageData}
                name={t('Nikita_Pospelov')}
                position={t('Global Business Director')}
                quote={t('About_Nikita_Pospelov')}
                region={region as AppRegionEnum}
              />
            )}
            {region === AppRegionEnum.Us && (
              <AuthorCard
                image={images.Paul_Goretsky.childImageSharp.gatsbyImageData}
                name={t('Paul_Goretsky')}
                position={t('Project manager')}
                quote={t('About_Paul_Goretsky')}
                region={region as AppRegionEnum}
              />
            )}
            <AuthorCard
              image={images.Mikhail_Gutenev.childImageSharp.gatsbyImageData}
              name={t('Mikhail_Gutenev')}
              position={t('Frontend Team Lead')}
              quote={t('About_Mikhail_Gutenev')}
              region={region as AppRegionEnum}
            />
            {region === AppRegionEnum.Ru && (
              <AuthorCard
                image={images.Vladimir_Zhigalev.childImageSharp.gatsbyImageData}
                name={t('Vladimir_Zhigalev')}
                position={t('Frontend Team Lead')}
                quote={t('About_Vladimir_Zhigalev')}
                region={region as AppRegionEnum}
              />
            )}
            <AuthorCard
              image={images.Vadim_Pashchenko.childImageSharp.gatsbyImageData}
              name={t('Vadim_Pashchenko')}
              position={t('Backend Team Lead')}
              quote={t('About_Vadim_Pashchenko')}
              region={region as AppRegionEnum}
            />
            <AuthorCard
              image={images.Gleb_Kossov.childImageSharp.gatsbyImageData}
              name={t('Gleb_Kossov')}
              position={t('Product Designer')}
              quote={t('About_Gleb_Kossov')}
              region={region as AppRegionEnum}
            />
          </div>
        </Container>
      </Section>
      <Section className={s.section}>
        <Container>
          <Typography variant="h2" size={40} mb={32}>
            {t('about_title3')}
          </Typography>
          <div className={s.our_values}>
            <div className={s.our_value}>
              <div className="flex">
                <div>
                  <ProfessionalismIcon />
                </div>
                <div>
                  <Typography variant="body1" size={18} color="blue">
                    {t('about_subtitle3.1')}
                  </Typography>
                  <Typography variant="body1" size={18} className="mt-2">
                    {t('about_lead3.1')}
                  </Typography>
                </div>
              </div>
            </div>
            <div className={s.our_value}>
              <div className="flex">
                <div>
                  <ResponsibilityIcon />
                </div>
                <div>
                  <Typography variant="body1" size={18} color="blue">
                    {t('about_subtitle3.2')}
                  </Typography>
                  <Typography variant="body1" size={18} className="mt-2">
                    {t('about_lead3.2')}
                  </Typography>
                </div>
              </div>
            </div>
            <div className={s.our_value}>
              <div className="flex">
                <div>
                  <InnovativenessIcon />
                </div>
                <div>
                  <Typography variant="body1" size={18} color="blue">
                    {t('about_subtitle3.3')}
                  </Typography>
                  <Typography variant="body1" size={18} className="mt-2">
                    {t('about_lead3.3')}
                  </Typography>
                </div>
              </div>
            </div>
            <div className={s.our_value}>
              <div className="flex">
                <div>
                  <ClientCentricityIcon />
                </div>
                <div>
                  <Typography variant="body1" size={18} color="blue">
                    {t('about_subtitle3.4')}
                  </Typography>
                  <Typography variant="body1" size={18} className="mt-2">
                    {t('about_lead3.4')}
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </Section>
      <Section className={s.section}>
        <Container>
          <Typography variant="h2" size={40} mb={32}>
            {t('about_title4')}
          </Typography>
          <Typography variant="body1" size={18} mb={16}>
            {t('about_lead4.1')}
          </Typography>
          <Typography variant="body1" size={18} mb={16}>
            {t('about_lead4.2')}
          </Typography>
          <Typography variant="body1" size={18} mb={16}>
            {t('about_lead4.3')}
          </Typography>
          {region === AppRegionEnum.Ru && (
            <Typography variant="body1" size={18} mb={16}>
              {t('about_lead4.4')}
            </Typography>
          )}
        </Container>
      </Section>
      {region === AppRegionEnum.Us && (
        <>
          <Section className={s.section}>
            <Container>
              <Typography variant="h2" size={40} mb={32}>
                {t('about_title5')}
              </Typography>
              <div className={s.iframe_content}>
                <iframe
                  title={t('about_title5')}
                  className="w-100 h-100"
                  src="https://pa.signax.io/public/album/6b2wb/folders/5fbc3213-4d97-408b-ba55-2183be3b798d/plans/bc22dc7d-b4ab-40dc-b3bd-0d82d020bf54"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </div>
            </Container>
          </Section>
          <Section className={s.section_careers}>
            <Container>
              <div className="flex justify-between">
                <div className={s.description_block}>
                  <Typography variant="h2" size={40} mb={16}>
                    {t('about_title6')}
                  </Typography>
                  <Typography variant="body1" size={18} mb={16}>
                    {t('about_lead6.1')}
                  </Typography>
                  <Typography variant="body1" size={18}>
                    {t('about_lead6.2')}
                  </Typography>
                  <Button
                    size="large"
                    className={s.button}
                    onClick={() => setModal(true)}
                  >
                    {t('Upload CV')}
                  </Button>
                </div>
                <div className={s.image_block}>
                  <GatsbyImage
                    image={images.image2.childImageSharp.gatsbyImageData}
                    title="SIGNAX company"
                    alt="SIGNAX company"
                  />
                </div>
              </div>
            </Container>
          </Section>
        </>
      )}
      {modal && (
        <ContactForm
          onClose={() => setModal(false)}
          title={t('Upload CV')}
          requestType="Upload CV"
        />
      )}
    </>
  )
}

export default AboutPage

const imagesQuery = graphql`
  query {
    imagesCompany: allFile(
      filter: { absolutePath: { regex: "/images/about/imagesCompany/" } }
      sort: { fields: name }
    ) {
      edges {
        node {
          relativePath
          name
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
        }
      }
    }
    banner: file(relativePath: { eq: "openspace-banner.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    bannerMobile: file(relativePath: { eq: "openspace-mobile-banner.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    image2: file(relativePath: { eq: "about/image-2.png" }) {
      childImageSharp {
        gatsbyImageData(width: 536, placeholder: BLURRED)
      }
    }
    Roman_Sigitov: file(relativePath: { eq: "author/Roman_Sigitov.png" }) {
      childImageSharp {
        gatsbyImageData(width: 180, placeholder: BLURRED)
      }
    }
    Alex_Popov: file(relativePath: { eq: "author/Alexander_Popov.png" }) {
      childImageSharp {
        gatsbyImageData(width: 180, placeholder: BLURRED)
      }
    }
    Nikita_Pospelov: file(relativePath: { eq: "author/Nikita_Pospelov.png" }) {
      childImageSharp {
        gatsbyImageData(width: 180, placeholder: BLURRED)
      }
    }
    Paul_Goretsky: file(relativePath: { eq: "author/Paul_Goretsky_.png" }) {
      childImageSharp {
        gatsbyImageData(width: 180, placeholder: BLURRED)
      }
    }
    Gleb_Kossov: file(relativePath: { eq: "author/Gleb_Kossov.png" }) {
      childImageSharp {
        gatsbyImageData(width: 180, placeholder: BLURRED)
      }
    }
    Mikhail_Gutenev: file(relativePath: { eq: "author/Mikhail_Gutenev.png" }) {
      childImageSharp {
        gatsbyImageData(width: 180, placeholder: BLURRED)
      }
    }
    Vadim_Pashchenko: file(
      relativePath: { eq: "author/Vadim_Pashchenko.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 180, placeholder: BLURRED)
      }
    }
    Vladimir_Zhigalev: file(
      relativePath: { eq: "author/Vladimir_Zhigalev.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 180, placeholder: BLURRED)
      }
    }
  }
`
